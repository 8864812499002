body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bariol-Bold';
  src: local('Bariol-Bold'), url(./assets/fonts/Bariol-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: local('SourceSansPro-Regular'), url(./assets/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';
  src: local('SourceSansPro-SemiBold'), url(./assets/fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Book';
  src: local('Gotham-Book'), url(./assets/fonts/Gotham-Book.otf) format('truetype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: local('Gotham-Bold'), url(./assets/fonts/Gotham-Bold.otf) format('truetype');
}

.linkText{
    color: #1f96a2 !important;
    text-decoration: none !important;
    cursor: pointer !important;
    margin-left: 20 !important;
    font-family: 'SourceSansPro-Regular'

}
.marginLeft{
  margin-left: 20px;
}
.releaseVersionLTS{
  background-color:grey;
  color:white !important;
}
.releaseVersionLTSText{
  color:white;
}
.releaseVersionSP{
  background-color:lightgrey;
}

div._form_3 form {
  padding: 0px;
}

.returnToHome {
  position: relative;
  top: 63px;
  left: 40px;
  color: white;
  font-family: 'Bariol-Bold';
  font-size: 16px;
}
.returnToHome > a {
  color: white !important;
  text-decoration: none !important;
}

.tabSpace{
  display: inline-block;
  margin-left: 7px;
}