.paperRectangle {
    height: 82px;
}

.paperCard {
    display: flex;
    align-Items: center !important;
    width: 100%;
    height: 100%;
}

.paperCardIconRectangle {
    width: 32px;
    height: 32px;
    margin: 0 16px 6px 0;
    padding: 6px;
    border-radius: 6px;
    background-color: rgba(124, 124, 124, 0.08);
}

.paperCardIcon {
    /* width: 10%;
    align-self: center; */
    width: 32px;
    height: 32px;
    margin: 0 16px 6px 0;
    padding: 6px;
    border-radius: 6px;
    background-color: rgba(124, 124, 124, 0.08);
}

.paperCardText {
    width: 76%;
    align-self: center;
}

.paperDescription {
    font-size: 12px;
    font-family: 'SourceSansPro';
    color: #8e8e8e !important;
    align-content: center !important;
}

.modulesDescription {
    font-size: 12px;
    font-family: 'SourceSansPro';
    color: #8e8e8e !important;
    align-content: center !important;
    width: 160px;
    display: flex;
}

.modulesDescription>div {
    margin-left: 10px;
}

.paperCardChip {
    width: 16%;
    margin-bottom: 10px;
    align-content: flex-end !important;
    align-self: flex-end !important;
    text-align: right !important;
}

.paperLinkText {
    color: #000000 !important;
    text-decoration: none !important;
    cursor: pointer !important;
    margin-left: 20 !important;
    font-size: small;
    font-family: 'SourceSansPro-SemiBold'
}

.paperChip {
    background-color: #8e8e8e !important;
    color: white !important;
    font-size: 12px !important;
    font-family: SourceSansPro !important;
    font-weight: 600 !important;
    height: 16px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
}

@media (max-width: 766px) {
    .paperCardText {
        width: 150px;
        align-self: center;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        -webkit-line-clamp: 3;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .paperDescription {
        display: none;
    }
    .paperCardChip {
        width: 31%;
        margin-bottom: 10px;
        align-content: flex-end !important;
        align-self: flex-end !important;
        text-align: right !important;
    }
}

.watchFace {
    height: 12px !important;
    width: 12px !important;
    fill: #fff !important;
}

body {
    background-color: #f5f5f5 !important;
}

.Basics {
    font-family: Bariol-Bold !important;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
}

@font-face {
    font-family: 'Bariol';
    src: local('Bariol'), url(/fonts/bariol_regular-webfont.ttf) format('truetype');
}

@font-face {
    font-family: 'SourceSansPro';
    src: local('SourceSansPro'), url(/fonts/SourceSansPro-Regular.otf) format('truetype');
}

.groupMarker {
    height: 90px;
    justify-content: end !important;
    justify-items: end !important;
    vertical-align: bottom;
}

.groupContent {
    align-self: flex-end !important;
}

.Training {
    height: 32px;
    margin: 24px 0;
    font-family: Gotham-Bold;
    font-size: 32px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: center;
    color: #fff;
}